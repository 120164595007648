import React from 'react';

export default class Mission extends React.Component{

    render(){
        return(
            <div className="card p-10 pl-20">
                <h5 className="fw-600 pink-text">MISSION</h5>
                <p>
                    Savante Consulting Limited will strive to reduce
                    and manage business risks by providing our clients and their financiers with
                    business decisions and timely executions of projects.
                </p>
                <h5 className="fw-600 pink-text">VISION</h5>
                <p >
                    Referenced as a reliable source of well-researched industry information
                    and discipline collaborator in project execution.
                </p>
            </div>
        );
    }
}