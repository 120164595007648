import React from 'react';
import SEO from "../../components/seo"
import Layout from "../../components/layout"

import Grid from '@material-ui/core/Grid';
import Mission from '../../components/Mission'
import ServicesMenu from '../../components/ServicesMenu'
import SliderBootstrapStatic from '../../components/SliderBootstrapStatic';

export default function ClinicalTrials(){

    return(
        <Layout>
            <SEO 
                title="Clinical Trials"
                description="Savante Consulting Limited will project-manage Phase III and IV clinical trials and Bridging studies to support Marketing Authorization Applications and achieve other desired outcomes for the client."
            />
            <br /><br />
            <SliderBootstrapStatic title="Clinical Trials" />

            <div className="container">
                <br />
                <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={8}>
                            <ServicesMenu />
                            <h5>Clinical Trials</h5>
                            <p className="pr-20 justify" style={{textAlign: 'justify'}}>
                            Savante Consulting Limited, as one of the  leading clinical research organizations in Africa, with operating offices in Nigeria and Kenya, will project-manage phase III and IV Clinical Trials and Bridging Studies to support Marketing Authorization Applications and achieve desired outcomes for clients. Savante is foremost among CROs in Nigeria and able to speedily get approvals for clinical trials from National Regulatory Agencies and Hospitals' Ethics Committees. 
Savante Consulting Limited can help clients select Clinical Trial Sites, choose investigators, recruit patients and provide logistics for data and clinical trial materials.

                            </p>
                            
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>  
                                <Mission />
                        </Grid>
                    </Grid>
                    <br /> <br />
            </div>
        </Layout>
    );
}