import React from 'react';
import { Link } from 'gatsby';
import './ServicesMenu.css'

export default class ServicesMenu extends React.Component {
  render() {

    return (
      <div className="services-menu hide-on-small-only"> 
        <ul>
            <li><Link activeClassName="active" className="service-menu-title" to="/services/regulatory-affairs">Regulatory Affairs</Link></li>
            <li><Link activeClassName="active" className="service-menu-title" to="/services/clinical-trails">Clinical Trials</Link></li>
            <li><Link activeClassName="active" className="service-menu-title" to="/services/pharmacovigilance">Pharmacovigilance</Link></li>
        </ul>
      </div>
    );
  }
}